
























import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'UPEIPsych1020A1Task2',
  components: {STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        questionsProvided: null,
      },
      questions: [
        {
          text: 'In the space below, please submit the questions you have created in response to Reading/Watching with Purpose.',
          inputName: 'questionsProvided',
        },
      ],
    };
  },
});
